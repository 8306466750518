var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "templateRoot" }, [
    _vm.allCameras
      ? _c(
          "div",
          { staticClass: "allCameras" },
          _vm._l(_vm.allCameras, function(camera) {
            return _c("AllPageCamera", {
              key: camera.id,
              attrs: { camera: camera }
            })
          }),
          1
        )
      : _vm.error
      ? _c("div", [_vm._v("An error occurred: " + _vm._s(_vm.error))])
      : _c("div", [_c("ScaleLoader")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }