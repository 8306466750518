<template>
	<div class="appRoot">
		<router-view></router-view>
	</div>
</template>

<script>

	export default {
		data()
		{
			return {
			};
		},
		computed:
		{
		},
		methods:
		{
		},
		created()
		{
		}
	};
</script>

<style scoped>
	.appRoot
	{
		font-size: 0px;
		height: 100%;
		width: 100%;
	}
</style>