var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "allPageCamera" }, [
    _c("a", { staticClass: "imgLink", attrs: { href: _vm.imageLink } }, [
      _c("img", { attrs: { src: _vm.imageUrl } })
    ]),
    _vm._v(" "),
    _c("a", { staticClass: "nameLink", attrs: { href: _vm.nameLink } }, [
      _vm._v(_vm._s(_vm.camera.name))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }